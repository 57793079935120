<template>
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <p class="modal-title">
          <i class="fas fa-database mr-2"></i>
          <span class="font-weight-bold">Account Details</span>
        </p>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="p-3">
          <div class="form-row">
            <div class="col-7">
              <div class="form-group">
                <label>Custodian</label>
                <input v-model="account.custodian" class="form-control form-control-sm" placeholder="Master"
                  :readonly="account.readonly(`master`)" />
                <small class="form-text text-muted">Account Custodian</small>
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label>Number</label>
                <input v-model="account.number" class="form-control form-control-sm" placeholder="Number"
                  :readonly="account.readonly(`number`)" />
                <small class="form-text text-muted">Account Number</small>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="col">
              <div class="form-group">
                <label>Name</label>
                <input v-model="account.name" class="form-control form-control-sm" placeholder="Name" />
                <small class="form-text text-muted">Account Name</small>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="col">
              <div class="form-group">
                <label>Household</label>
                <select v-model="account.household" class="form-control form-control-sm"
                  :disabled="account.disabled(`household`)">
                  <option v-for="h in account.households" :key="h._id">
                    <span v-text="h.name"></span>
                  </option>
                </select>
                <small class="form-text text-muted">Account Household</small>
              </div>
            </div>
          </div>
          <div v-if="account._id" class="form-row">
            <div class="col-4">
              <div class="form-group">
                <label for="accountFundedDate">Account Funded Date</label>
                <date-picker v-model="account.fundedDate" format="MMM D, YYYY" style="  width: 100%;"
                  placeholder="" :disabled-date="notAfterToday" ></date-picker>
                <small class="form-text text-muted">Select account funded date</small>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer pr-3">
        <div class="text-right">
          <remove-button v-if="!account.isListedUnderCustodian" :button="account.remove"></remove-button>
          <button type="button" class="btn btn-sm btn-primary ml-2" :disabled="account.disabled(`save`)"
            v-on:click="account.click(`save`)">
            <span class="button">Save</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
button {
  width: 80px;
}

p.modal-title i,
p.modal-title span {
  opacity: 0.7;
}
</style>

<script>
import { removeButton } from "../../../../component";
import { Api, Modal } from "../../../../factory";
import { alert, session } from "../../../../service";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

export default {
  get components() {
    return {
      removeButton,
      DatePicker
    };
  },
  props: {
    data: {
      type: Object,
      required: true,
      validator: (o) => o.hasOwnProperty(`account`),
    },
  },
  data() {
    return {
      account: ``,
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      var { data } = this;
      var account = {
        _custodian: (o) => `${o.id} (${o.name || o.type})`,
        _keys: [
          `_id`,
          `contractId`,
          `household`,
          `households`,
          `name`,
          `number`,
          `fundedDate`
        ],
        async _load() {
          var { account, methods, isListedUnderCustodian } = data;
          // console.log("data", data);
          // console.log("data.account", data.account);
          // console.log("data.account.custodianId", data.account.custodianId);
          try {
            var state = session.get(`state`);
            // console.log(state.custodian);
            var [{ data: custodian }, { data: households }] = await Promise.all(
              [
                Api.get(
                  `custodians/${data.account.custodianId ?? state.custodian._id
                  }`
                ),
                Api.get(`households?_sort=name`),
              ]
            );
            this.custodian = this._custodian(custodian);
            this.isListedUnderCustodian = isListedUnderCustodian;
            this.households = households;
            this.fundedDate = new Date(account.fundedDate);
            this.household = data.household
              ? data.household.name
              : account.householdId
                ? households.find((h) => h._id == account.householdId).name
                : ``;
          } catch (e) {
            console.error(e);
            if (methods && typeof methods.error == `function`)
              return methods.error(e);
            alert.error(e.message);
          }
        },
        async _remove() {
          try {
            var { account: a, methods } = data;
            if (a._id) {
              var r = await Api.delete(`accounts/${a._id}`);
              if (methods && typeof methods.success == `function`)
                return data.methods.success(r.data);
            }
            Modal.close();
          } catch (e) {
            console.error(e);
            if (methods && methods.error == `function`) return methods.error(e);
            alert.error(e.message);
          }
        },
        async _save() {
          var oldHouseholdId = undefined
          this._keys.forEach((key) => {
            switch (key) {
              case `_id`:
              case `contractId`:
              case `households`:
                return;
              case `household`:
                var h = this.households.find((h) => h.name.trim() == this[key].trim());
                if (h && h._id != data.account.householdId) {
                  oldHouseholdId = data.account.householdId
                  data.account.householdId = h._id;
                }
                break;
              case `fundedDate`:
                if (this.fundedDate) {
                  data.account[key] = Math.round(this.fundedDate.getTime() / 1000);
                }
              case `master`:
              case `number`:
                if (!this._id) data.account[key] = this[key];
                break;
              default:
                data.account[key] = this[key];
            }
          });
          var { account, methods } = data;
          if (methods && typeof methods.submit == `function`)
            return data.methods.submit(data);
          try {
            var r = account._id
              ? await Api.put(`accounts/${account._id}`, {...account, oldHouseholdId})
              : await Api.post(`accounts`, account);
            if (methods && typeof methods.success == `function`)
              return data.methods.success(r.data);
            Modal.close();
          } catch (e) {
            console.error(e);
            if (methods && methods.error == `function`) return methods.error(e);
            alert.error(e.message);
          }
        },
        class(type) {
          switch (type) {
            case `remove`:
              return this._delete ? `btn-danger` : `btn-outline-danger`;
            default:
              throw new Error(`Invalid class type, ${type}!`);
          }
        },
        click(type) {
          switch (type) {
            case `remove`:
              return this._remove();
            case `save`:
              return this._save();
            default:
              throw new Error(`Invalid click type, ${type}!`);
          }
        },
        custodian: ``,
        isListedUnderCustodian: undefined,
        disabled(type) {
          switch (type) {
            case `remove`:
              return !data.account._id || data.account.contractId;
            case `save`:
              return !this.household || data.account._id
                ? this.name.length < 1
                : this.number.length < 1 || this.name.length < 1;
            case `household`:
              return data.account.contractId != null;
            default:
              throw new Error(`Invalid disabled type, ${type}!`);
          }
        },
        init() {
          this.none = `-`;
          this.ready = false;
          this.fundedDate = null; 
          this._keys.forEach((key) => {
            this[key] = data.account[key] || ``;
          });
          this.remove = {
            click: () => {
              this._remove();
            },
            disabled: ``,
          };
          this._load();
          return this;
        },
        none: ``,
        readonly(type) {
          switch (type) {
            case `master`:
            case `number`:
              return (
                data.account.hasOwnProperty(`_id`) ||
                data.account.hasOwnProperty(`select`)
              );
            default:
              throw new Error(`Invalid readonly type!`);
          }
        },
        ready: ``,
        remove: ``,
      };
      this.account = account.init();
    },
    notAfterToday(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      return date.getTime() > today.getTime();
    },
  },
};
</script>
